<template>
  <div
    class="d-flex align-center"
    v-if="value && value.length > 0"
  >
    <template v-if="value.length === 1">
      <computed-document-ref
        :id="value[0].id"
        :document-type="value[0].type"
        :options="computedOptions"
        :account-id="accountId"
      />
    </template>
    <template v-else>
      <computed-document-ref
        v-for="(v, i) in computedItems"
        :key="'assigned' + i"
        class="mr-4 align-center"
        :id="v.id"
        :document-type="v.type"
        :options="computedOptions"
        :account-id="accountId"
      />
      <v-chip v-if="sizeItems">{{sizeItems}}</v-chip>
    </template>
  </div>
</template>

<script>
import Column from '@/pages/search/controllers/column'

export default {
  inheritAttrs: false,
  components: {
    ComputedDocumentRef: () => import('@/components/documents-ref/computed-document-ref')
  },
  computed: {
    computedOptions () {
      return Object.assign({}, this.column.options, { hideNavigation: false, hideFullname: false, hideEmail: false })
    },
    computedItems () {
      return this.value.slice(0, 2)
    },
    sizeItems () {
      return this.value.length <= 2 ? '' : this.value.length >= 10 ? '8+' : '+' + (this.value.length - 2).toString()
    }
  },
  props: {
    value: {
      type: Array
    },
    column: Column,
    accountId: String
  }
}
</script>
